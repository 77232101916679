@import "styles/app";

.galleryModal {
  display: flex;
  flex-direction: column;
  background: $colorWhite;
  padding: 0;
  gap: 0;
  height: 100%;

  @include mediaLg {
    gap: 16px;
    margin: 0;
    background: transparent;
    height: calc(100vh - 40px);
  }


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $colorWhite;
    backdrop-filter: blur(10px);
    @include p3;
    padding-inline: 0;
    padding-block: 20px;
    margin-inline: 16px;
    border-bottom: 1px solid $colorDarkWhite;

    @include mediaLg {
      background: #EDEDF0;
      padding: 16px 24px;
      margin: 0;
      border: none;
    }

    button {
      background: $colorWhite;
      @include mediaLg {
        min-width: 44px;
        height: 44px;
      }
    }
    &--tabs {

      @include mediaLg {
        justify-content: center;
      }
      
      button {
        @include mediaLg {
          height: 44px;
        }
      }
    }
    ul {
      flex-grow: 1;
    }

  }

  &__headerActions {
    display: flex;
    align-items: center;
    gap: 10px;
    // min-width: 120px;
  }

  &__body {
    padding: 32px 0 8px;
    background: $colorWhite;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    flex: 1;


    @include mediaLg {
      background: #EDEDF0;
      height: calc(100vh - 132px);
      padding: 20px;

      img {
        // padding-inline: 80px;
      }
    }

  }

  .tabs {
    @include p3;
    font-weight: 700;
    justify-content: center;
    margin: 20px 0;
    @include mediaLg {
      margin: 0;

    }
  }
}

.tabPanel {
  &:global(.react-tabs__tab-panel--selected) {
    height: 100%;
    overflow: hidden;
  }
}

.backBtn {
  @include mediaLg {
    display: none;
  }
}
