@import "styles/app";

@mixin fade {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.60) 100%);
    transition: opacity .25s;
  }
}

.grid {
  position: relative;
  display: grid;
  height: 375px;
  grid-template: "main";
  grid-gap: 8px;

  @include mediaMd {
    grid-template: "main b" auto
                "main d" auto;
  }

  @include mediaLg {
    height: 440px;
    grid-template: "main b c" auto
                "main d e" auto / 2fr 1fr 1fr;
  }
}

.photo {
  position: relative;
  transition: .25s filter;
  cursor: pointer;
  overflow: hidden;

  img {
    object-fit: cover;
    transition: .25s transform;
  }

  &:first-child {
    grid-area: main;
  }

  &:first-child {
    @include fade;
  }

  &:nth-child(2),
  &:nth-child(3) {
    display: none;

    @include mediaMd {
      display: block;
    }
  }

  &:nth-child(4),
  &:nth-child(5) {
    display: none;

    &::after {
      background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.60) 100%);
    }

    @include mediaLg {
      display: block;
    }
  }

  &:hover img {
    transform: scale(1.025);
  }
}

.header,
.footer {
  position: absolute;
  display: flex;
  gap: 12px;
  left: 0;
  padding-inline: 16px;
  bottom: 20px;
  box-sizing: border-box;
  z-index: 1;

  button {
    display: flex;
    gap: 4px;

    &:not(:hover) {
      background: rgba(0, 0, 0, 0.1);
      text-shadow: 0 0 1px rgba(#000, .6);
    }
  }

  @media (max-width: $screenSm) {
    width: 100%;
  }

  @include mediaMd {
    padding-inline: 20px;
  }
}

.header {
  top: 20px;
  bottom: auto;
}

.videoCover {
  display: none !important;
  overflow: hidden;
  aspect-ratio: unset !important;
  @include fade;

  @include mediaLg {
    display: block !important;
  }

  &:hover img {
    transform: scale(1.025);
  }

  img {
    object-fit: cover;
    transition: .25s transform;
  }
}