@import "styles/app";
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.gallery {
  animation: appear 1s ease forwards;
  width: 100vw;
  height: 364px;
  position: relative;

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    &--center {
      justify-content: center;
    }
    &--small {
      @include mediaLg {
        // max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      @include mediaEx {
        // max-width: 740px;
        justify-content: center;
      }
    }
  }

  @include mediaLg {
    height: 503px;
  }
  &--fullSize {
    width: 100%;
    height: 100%;

    --mobileSliderFooterOffset: -20px;
    display: flex;

    .gallery__wrapper {
      margin: 0 auto;
      overflow: hidden;
      width: auto;
      // max-width: 890px;
      @include mediaMd {
        width: calc(100% - 70px * 2);
      }
      @include mediaEx {
        width: calc(100% - 170px * 2);
      }
    }

    .gallery__slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 100% !important;
      max-width: 100% !important;
      height: calc(100% - 39px) !important;
      opacity: 0;
      @include mediaSm {
        height: calc(100% - 62px) !important;
        width: auto !important;
        max-width: 100% !important;
      }
      @include mediaGt {
        height: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
      }
      &--active {
        opacity: 1;
      }

      &_withName img {
        top: 46px !important;
        height: calc(100% - 130px - var(--mobileSliderFooterOffset)) !important;

        @include mediaGt {
          top: 0 !important;
          height: calc(100% - 46px) !important;
        }
      }

      img {
        object-fit: cover;
      }
    }
    .gallery__buttons {
      display: none;
      pointer-events: none;

      @include mediaLg {
        display: flex;
        bottom: unset;
        justify-content: space-between;
      }
    }

    .gallery__button {
      pointer-events: all;
      @include mediaLg {
        margin: 0;
      }
      &--prev {
        left: 0;
        margin-left: 0;
      }
      &--next {
        margin-right: 0;
        right: 0;
      }

      @media (max-width: $screenLg) {
        bottom: var(--mobileSliderFooterOffset);
      }
    }
  }

  &.theme-default {
    --primary-color: #{$colorAccent};
    --secondary-color: #8a949e;
  }

  &.theme-light {
    --primary-color: #{$colorWhite};
    --secondary-color: #{rgba($colorWhite, 0.5)};
  }
  .theme-transparent {
    --primary-color: #{$colorWhite};
    --secondary-color: #{rgba($colorWhite, 0.5)};
  }

  &__slideName {
    font-size: 18px;

    @include mediaLg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      margin: 0;
    }

    @media (max-width: $screenSm) {
      margin-inline: 16px;
    }
  }

  &__slide {
    width: min-content;
    position: relative;
    overflow: hidden;
    min-width: 0;
    opacity: 0.5;
    animation: hide 0.6s;
    transition: opacity 0.2s;
    margin: 0 6px;

    flex: 0 0 auto;
    + .gallery__count {
      opacity: 0.5;
    }
    @include mediaLg {
      margin: 0 33px;
      height: 503px;
    }

    &--active {
      opacity: 1;
      + .gallery__count {
        opacity: 1;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    bottom: -5px;
    translate: none;
    pointer-events: none;
    @include mediaLg {
      position: absolute;
      bottom: unset;
      /* diff between slide height and pic height / 2 */
      top: 45%;
      translate: 0 -50%;
      justify-content: space-between;
    }
  }
  &__button {
    pointer-events: all;
    z-index: 10 !important;
    pointer-events: all;
    &--prev {
      @include mediaLg {
        margin-left: 13%;
      }
    }
    &--next {
      margin-right: 5%;
      @include mediaLg {
        margin-right: 13%;
      }
    }
  }
  &__card {
    pointer-events: none;
    object-fit: cover;
    max-width: 343px !important;
    min-width: 343px !important;
    min-height: 303px !important;
    max-height: 303px !important;
    @include mediaLg {
      height: 100%;
      object-fit: contain;
      max-width: 100% !important;
      min-width: unset !important;
      min-height: unset !important;
      max-height: unset !important;
    }
  }
  &__count {
    display: none;

    @include mediaLg {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      translate: -50%;
      span {
        opacity: 0.5;
      }
    }
  }

  &__count--fullsize {
    @include mediaLg {
      left: 0;
      translate: 0;
      span {
        color: var(--secondary-color);
      }
    }
  }

  &__slideWrapper {
    height: 100%;
    position: relative;
    transition: width 0.3s;
    display: flex;
    align-items: center;
  }
}

.transform{
  // width: 100% !important; 
  justify-content: center;
  @include mediaLg{
    // height: 100% !important;
  }
  &__wrapper {
    width: 100% !important;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    @include mediaLg{
      // height: 100% !important;
    }
  }
  &_zoom{
    transform: translate(0px, 0px) scale(1) !important;
  }
}