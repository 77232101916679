@import "styles/app.scss";

.video {
  &__overlay {
    position: relative;
    // min-height: 700px;
    aspect-ratio: 16 / 9;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    &:hover {
      .video__play {
        background: $colorWhite;
        color: $colorAccent;

        path {
          fill: $colorAccent;
        }
      }
    }
  }

  &__thumbnail {
    width: auto;
    height: 100%;
    max-height: max-content;
    margin: 0 auto;
    object-position: center;
    object-fit: contain;
    cursor: pointer;
    zoom: 2 !important;
    // pointer-events: none;
    &--inactive {
      z-index: 0;
      display: none;
    }
  }

  &__play {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 40px;
    height: 40px;
    border: 1.6px solid $colorWhite;
    border-radius: 30px;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all;
    color: $colorWhite;
    backdrop-filter: blur(2px);
  }

  &__iframe {
    min-height: 100%;
    max-height: 100%;
    width: stretch;
    width: -webkit-fill-available;
    width: -moz-available;
    opacity: 0;

    &--active {
      opacity: 1;
    }
  }

  &__description {
    position: absolute;
    bottom: -40px;
    left: 50%;
    translate: -50% 0;
    color: $colorWhite;
    text-align: center;
  }
}
